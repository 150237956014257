
import axios from "axios";
// Swagger schema for the API we are submitting to
// https://app.swaggerhub.com/apis/azimnitski/Lead/1.0.4

export default {
	name: "CoreBlockForm",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		dataSite: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			formValidationId: null,
			form: {
				// fields that are always included
				firstName: null,
				lastName: null,
				address: null,
				city: null,
				state: null,
				zip: null,
				emailAddress: null,
				phoneNumber: null,
				birthDate: null,

				// fields that are toggled on
				openText1: null,
				openText2: null,
				openText3: null,
				openText4: null,
				openText5: null,
				languagePref: null,
				bestTimeToCall: null,
				contactMethod: null,
				linkedinUrl: null,
				leadConcerns: [],

				// the url of this page -- this isn't ref'd anywhere on NYL endpoint. Can add later if needed.
				// pageUrl: null,

				// tells us where the lead came from i.e. google, bing, direct
				leadSource: "FUSION", // always fusion

				sourceCode: null, // values here: https://fusion92.sharepoint.com/:x:/s/NewYorkLife/EXkXq09zb4BFl-3OFknfg88BOrs99Jvk3YupzWfT5KWX2w

				// no idea what these fields are for

				leadProcessType: null, // agentPersWeb, goWeb, goWebRec
				campaignProgramCode: null, // C4, A2
				campaignCode: null, // PAW667, PGW668, UL0235

				// these fields are not included in the backend test
				// which was successfully returning a 200
			},
			status: null, // null, "success", "fail"
			stateOptions: [
				{ value: "AL", text: "AL" },
				{ value: "AK", text: "AK" },
				{ value: "AZ", text: "AZ" },
				{ value: "AR", text: "AR" },
				{ value: "CA", text: "CA" },
				{ value: "CO", text: "CO" },
				{ value: "CT", text: "CT" },
				{ value: "DC", text: "DC" },
				{ value: "DE", text: "DE" },
				{ value: "FL", text: "FL" },
				{ value: "GA", text: "GA" },
				{ value: "HI", text: "HI" },
				{ value: "ID", text: "ID" },
				{ value: "IL", text: "IL" },
				{ value: "IN", text: "IN" },
				{ value: "IA", text: "IA" },
				{ value: "KS", text: "KS" },
				{ value: "KY", text: "KY" },
				{ value: "LA", text: "LA" },
				{ value: "ME", text: "ME" },
				{ value: "MD", text: "MD" },
				{ value: "MA", text: "MA" },
				{ value: "MI", text: "MI" },
				{ value: "MN", text: "MN" },
				{ value: "MS", text: "MS" },
				{ value: "MO", text: "MO" },
				{ value: "MT", text: "MT" },
				{ value: "NE", text: "NE" },
				{ value: "NV", text: "NV" },
				{ value: "NH", text: "NH" },
				{ value: "NJ", text: "NJ" },
				{ value: "NM", text: "NM" },
				{ value: "NY", text: "NY" },
				{ value: "NC", text: "NC" },
				{ value: "ND", text: "ND" },
				{ value: "OH", text: "OH" },
				{ value: "OK", text: "OK" },
				{ value: "OR", text: "OR" },
				{ value: "PA", text: "PA" },
				{ value: "RI", text: "RI" },
				{ value: "SC", text: "SC" },
				{ value: "SD", text: "SD" },
				{ value: "TN", text: "TN" },
				{ value: "TX", text: "TX" },
				{ value: "UT", text: "UT" },
				{ value: "VT", text: "VT" },
				{ value: "VA", text: "VA" },
				{ value: "WA", text: "WA" },
				{ value: "WV", text: "WV" },
				{ value: "WI", text: "WI" },
				{ value: "WY", text: "WY" },
			],
			stateSelectIsOpen: null,
			contactTimeOptions: [
				{ value: "Morning", text: "Morning" },
				{ value: "Afternoon", text: "Afternoon" },
				{ value: "Evening", text: "Evening" },
			],
			contactMethodOptions: [
				{ value: "Email", text: "Email" },
				{ value: "Phone", text: "Phone" },
			],
			languageOptions: [
				{ value: "English", text: "English" },
				{ value: "Cantonese", text: "Cantonese" },

				{ value: "Farsi", text: "Farsi" },
				{ value: "French", text: "French" },
				{ value: "Fukienese", text: "Fukienese" },
				{ value: "German", text: "German" },
				{ value: "Gujarati", text: "Gujarati" },
				{ value: "Hebrew", text: "Hebrew" },
				{ value: "Hindi", text: "Hindi" },
				{ value: "Japanese", text: "Japanese" },
				{ value: "Korean", text: "Korean" },
				{ value: "Lao", text: "Lao" },
				{ value: "Mandarin", text: "Mandarin" },
				{ value: "Polish", text: "Polish" },
				{ value: "Portuguese", text: "Portuguese" },
				{ value: "Punjabi", text: "Punjabi" },
				{ value: "Russian", text: "Russian" },
				{ value: "Shanghainese", text: "Shanghainese" },
				{ value: "Spanish", text: "Spanish" },
				{ value: "Tagalog", text: "Tagalog" },
				{ value: "Taiwanese", text: "Taiwanese" },
				{ value: "Urdu", text: "Urdu" },
				{ value: "Vietnamese", text: "Vietnamese" },
			],
			userAge: null,
			selectedState: "",
			baseURL:
				process.env.NUXT_ENV_API_BASE_URL ||
				"https://localhost:7283/api",
		};
	},
	computed: {
		formType() {
			return this.settings.formType;
		},

		hasInterestFields() {
			if (this.settings.formType !== "agentNewsletter") {
				return this.settings?.interestsFieldset;
			}
			return false;
		},
		interestOptions() {
			if (this.hasInterestFields) {
				if (
					this.settings.formType === "goConsumer" ||
					this.settings.formType === "agentConsumer" ||
					this.settings.formType === "agentCustom"
				) {
					return [
						{ value: "Life Insurance" },
						{
							value: "Retirement Planning",
						},
						{
							value: "Retirement and Other Savings",
						},
						{
							value: "Estate, Trust & Legacy Planning",
						},
					];
				}
				if (this.settings.formType === "goRecruiter") {
					return [
						{
							value: "Financial Professional",
						},
						{ value: "College Intern" },
						{ value: "Fast Track" },
						{ value: "Management" },
					];
				}
			}
			return [];
		},

		hasAddressFields() {
			if (
				this.formType === "agentNewsletter" ||
				this.formType === "agentCustom"
			) {
				return false;
			}
			return true;
		},

		hasPhoneFields() {
			if (this.formType === "agentNewsletter") {
				return false;
			}
			return true;
		},

		isPhoneRequired() {
			return (
				this.requireContactMethod ||
				(!this.form.emailAddress && this.formType === "agentCustom")
			);
		},

		isEmailRequired() {
			return (
				this.formType === "agentNewsletter" ||
				this.requireContactMethod ||
				(!this.form.phoneNumber && this.formType === "agentCustom")
			);
		},

		hasLanguageFields() {
			if (this.formType === "agentNewsletter") {
				return false;
			}
			return !!this.settings?.languageFieldset;
		},

		hasBestTimeFields() {
			if (this.formType === "agentNewsletter") {
				return false;
			}
			return !!this.settings?.bestTimeFieldset;
		},

		hasBestMethodFields() {
			if (this.formType === "agentNewsletter") {
				return false;
			}
			return !!this.settings?.bestMethodFieldset;
		},
		hasLinkedinFields() {
			if (this.formType === "agentNewsletter") {
				return false;
			}
			return !!this.settings?.linkedinFieldset;
		},
		hasBirthDateFields() {
			if (this.formType === "agentNewsletter") {
				return false;
			}
			return (
				this.formType === "goConsumer" ||
				this.formType === "agentConsumer"
			);
		},
		hasOpenTextFields() {
			if (this.formType === "agentNewsletter") {
				return false;
			}
			return {
				1: !!this.settings?.openTextFieldset1,
				2: !!this.settings?.openTextFieldset2,
				3: !!this.settings?.openTextFieldset3,
				4: !!this.settings?.openTextFieldset4,
				5: !!this.settings?.openTextFieldset5,
			};
		},
		openTextFieldLabel() {
			return (type) => {
				switch (type) {
					case 2:
						return this.settings?.openTextLabel2 || "";
					case 3:
						return this.settings?.openTextLabel3 || "";
					case 4:
						return this.settings?.openTextLabel4 || "";
					case 5:
						return this.settings?.openTextLabel5 || "";
					default:
						return this.settings?.openTextLabel1 || "";
				}
			};
		},
		marketerNumber() {
			if (
				this.formType === "goConsumer" ||
				this.formType === "goRecruiter"
			) {
				return null;
			}
			return this.dataSite.user?.ddcUserData?.marketerNo || null;
		},
		orgUnitCD() {
			if (
				this.formType === "agentCustom" ||
				this.formType === "agentConsumer" ||
				this.formType === "agentNewsletter"
			) {
				return null;
			}
			return this.dataSite.user?.ddcUserData?.orgUnitCode || null;
		},
		requireContactMethod() {
			if (
				this.formType === "agentConsumer" ||
				this.formType === "goRecruiter" ||
				this.formType === "goConsumer"
			) {
				return true;
			}
			return false;
		},
		onSuccessMessage() {
			if (this.formType === "goRecruiter") {
				return "Thank you for your interest in joining our team at New York Life. A member of our team will contact you soon.";
			}
			if (this.formType === "agentNewsletter") {
				return "Thank you for reaching out and signing up for our newsletter.";
			}
			return "Thank you for reaching out. A member of our team will contact you soon.";
		},
	},
	watch: {
		form(val) {
			this.form = val.replace(/\W/g, "");
		},
	},
	async mounted() {
		await this.initializeReCAPTCHA();
	},
	beforeDestroy() {
		this.$recaptcha.destroy();
	},
	created() {
		this.formValidationId = this.generateId();
	},
	methods: {
		async submit() {
			const valid = await this.$refs.observer.validate();
			if (valid) {
				this.GTMForm();
				if (await this.passesReCAPTCHA()) {
					this.status = "pending";

					try {
						const payload = {
							...this.form,
							marketerNumber: this.marketerNumber,
							orgUnitCD: this.orgUnitCD,
							siteId: this.dataSite.id,
							acf2id: this.acf2id,
							formType: this.formType,
							referrer: document.referrer,
						};

						payload.customFields = [];

						if (payload.zip)
							payload.zip = payload.zip.match(/\d+/g).join("");
						if (payload.phoneNumber)
							payload.phoneNumber = payload.phoneNumber
								.match(/\d+/g)
								.join("");

						if (payload.openText1) {
							payload.customFields.push({
								id: "1",
								label: this.openTextFieldLabel(1),
								value: payload.openText1,
							});
						}
						if (payload.openText2) {
							payload.customFields.push({
								id: "2",
								label: this.openTextFieldLabel(2),
								value: payload.openText2,
							});
						}
						if (payload.openText3) {
							payload.customFields.push({
								id: "3",
								label: this.openTextFieldLabel(3),
								value: payload.openText3,
							});
						}
						if (payload.openText4) {
							payload.customFields.push({
								id: "4",
								label: this.openTextFieldLabel(4),
								value: payload.openText4,
							});
						}
						if (payload.openText5) {
							payload.customFields.push({
								id: "5",
								label: this.openTextFieldLabel(5),
								value: payload.openText5,
							});
						}
						if (this.formType === "agentNewsletter") {
							payload.customFields = [
								{
									id: "1",
									label: "call to action",
									value: "newsletter",
								},
							];
						}
						// nyl requires semicolon delimited string
						if (payload.leadConcerns) {
							const interestString =
								payload.leadConcerns.join(";");
							payload.leadConcerns = interestString;
						}

						delete payload.openText1;
						delete payload.openText2;
						delete payload.openText3;
						delete payload.openText4;
						delete payload.openText5;

						const response = await axios.post(
							`${this.baseURL}/leads/submit`,
							payload
						);
						if (response.status === 200) {
							this.status = "success";
						} else {
							this.status = "fail";
						}
					} catch {
						this.status = "fail";
					}
				}
				this.$recaptcha.reset();
			}
		},
		async passesReCAPTCHA() {
			try {
				const responseToken = await this.$recaptcha.execute("form");
				// pass token to backend endpoint returning pass/fail
				const response = await axios.post(
					`${this.baseURL}/tooling/submit/recaptcha`,
					{ responseToken }
				);
				return response.data?.success || false;
			} catch (e) {
				console.log(e);
			}
			return false;
		},
		async initializeReCAPTCHA() {
			try {
				await this.$recaptcha.init();
			} catch (e) {
				console.error(e);
			}
		},
		preventAngleBracket(event) {
			if (/<|>/gi.test(event.key)) {
				event.preventDefault();
			}
		},
		GTMForm() {
			this.$gtm.push({
				event: "nyl-form",
				lead_form: this.formType,
				lead_form_long: this.dataSite?.user.displayName || "",
				lead_form_category: this.getLeadFormCategory(this.formType),
			});
		},
		getLeadFormCategory(formType) {
			let category = "";
			switch (formType) {
				case "agentNewsletter":
				case "agentConsumer":
				case "agentCustom":
					category = "Customer";
					break;
				default:
					category = formType;
			}

			return category;
		},
		checkOpenTextFields(type) {
			if (this.formType !== "agentCustom") {
				return false;
			}
			return this.hasOpenTextFields[type];
		},
		generateId(length = 4, otherIds = []) {
			let flag = true;
			while (flag) {
				const letters = "bcdfghjkmnpqrstvwxyz";
				const characters = letters + "23456789";
				let id = letters[Math.floor(Math.random() * letters.length)];
				for (let i = 1; i < length; i++) {
					id +=
						characters[
							Math.floor(Math.random() * characters.length)
						];
				}
				if (otherIds.includes(id)) {
					continue;
				}
				flag = false;
				return id;
			}
		},
	},
};
